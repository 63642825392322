import Initializer from 'lib/initializer';
import './index.scss';

Initializer.registerComponent('breakers/fan_mix_breaker', function(element, data) {

  // This function will be called as a jsonp callback by Coral. It should
  // replace the first story slot in the Fan Mix breaker with the most actively
  // discussed story, according to Coral.
  // The Rails server should have rendered a backup story for that first slot
  // in case anything goes wrong here or we don't get adequate data.
  window.fanMixCoralActiveStory = function(coralData) {
    var exclusions = null;
    var exclusionElement = document.querySelector('#fan-mix-breaker-exclusions');
    if (exclusionElement && exclusionElement.textContent) {
      // exclusions should be a list of slugs/paths that are already prominent
      // on the page, either in the Fan Mix breaker or in the hero
      exclusions = JSON.parse(exclusionElement.textContent);
    }

    var stories = coralData.stories || [];

    for (var story of stories) {
      // Slugs in exclusion list will not have leading slash
      var slug = new URL(story.url).pathname.replace(/^\//, '');
      if (exclusions && exclusions.includes(slug)) {
        // i.e., this story is already prominent on the page
        continue;
      } else {
        // either we don't have an exclusion list available or the current
        // story is not in the list of exclusions. place the current story in
        // the Coral slot and stop iteration.
        var img = document.querySelector('#fan-mix-coral-story img');
        var title = document.querySelector('#fan-mix-coral-story .c-fan-mix-breaker__entry-title a');
        var subtitle = document.querySelector('#fan-mix-coral-story .c-fan-mix-breaker__entry-subtitle');
        var anchors = document.querySelectorAll('#fan-mix-coral-story a');
        var commentCount = document.querySelector('#fan-mix-coral-story a span[data-coral-url]');
        img.setAttribute('src', story.image);
        title.innerText = story.title;
        anchors.forEach((a) => a.setAttribute('href', story.url)); // eslint-disable-line no-loop-func
        // Set it this way to we don't erase the comment count node
        subtitle.firstChild.data = 'Top Comments last 24hrs';
        commentCount.innerText = story.count;
        break;
      }
    }
  };

  var coralScript = document.createElement('script');
  coralScript.src = data.jsonp_url;
  element.appendChild(coralScript);
}, {
  priority: Initializer.HIGH
});
