import './index.scss';
import Initializer from 'lib/initializer';

Initializer.registerComponent('breakers/podcast_player', function(element) {

  // This is for the subscribe menu popover

  function popover() {

    var subscribeButton = element.querySelector('.c-podcast-player__title-subscribe button');
    var popover = element.querySelector('.c-podcast-player__title-subscribe--popover');

    function showPopover() {
      subscribeButton.setAttribute('aria-expanded', 'true');
      popover.classList.remove('c-podcast-player__title-subscribe--popover-hidden');
    }

    function hidePopover() {
      subscribeButton.setAttribute('aria-expanded', 'false');
      popover.classList.add('c-podcast-player__title-subscribe--popover-hidden');
    }

    function handleSubscribeButtonClick() {
      if (popover.classList.contains('c-podcast-player__title-subscribe--popover-hidden')) {
        showPopover();
      } else {
        hidePopover();
      }
    }

    subscribeButton.setAttribute('aria-expanded', 'false');
    subscribeButton.addEventListener('click', handleSubscribeButtonClick);

  }

  popover();

});


